import sgImgS1 from '../images/service-single/web/img-1.jpg'
import sgImgS2 from '../images/service-single/web/img-2.jpg'
import sgImgS3 from '../images/service-single/web/img-3.jpg'

import brImgS1 from '../images/service-single/app/img-1.jpg'
import brImgS2 from '../images/service-single/app/img-2.jpg'
import brImgS3 from '../images/service-single/app/img-3.jpg'

import uxImgS1 from '../images/service-single/brand/img-1.jpg'
import uxImgS2 from '../images/service-single/brand/img-2.jpg'
import uxImgS3 from '../images/service-single/brand/img-3.jpg'

import dvImgS1 from '../images/service-single/market/img-1.jpg'
import dvImgS2 from '../images/service-single/market/img-2.jpg'
import dvImgS3 from '../images/service-single/market/img-3.jpg'



const Services = [
    {
        Id: '1',
        sImgS:sgImgS1,
        sTitle: 'Website Design',
        description:'We make beautiful websites for you. Start with the custom design, and we develop it in WordPress. Whatever your business needs, and whatever you want, we bring your imagination to life.',
        des2:'We make beautiful websites for you. Start with the custom design, and we develop it in WordPress.',
        des3:'We are a team of experienced and creative web designers who can help you create a stunning and user-friendly website for your business. Whether you need a simple landing page, a complex site, or a custom web application, we have the skills and expertise to deliver it. We use the latest technologies and best practices to ensure that your website is fast, secure, and responsive. We also offer maintenance and support services to keep your website running smoothly. Contact us today to get a free quote and consultation for your web design project.',
        capabilities: [
            'We create responsive and user-friendly websites that suit your needs and preferences',
            'We use the latest technologies and best practices to ensure high performance and security',
            'We offer a range of services, from web design and development to hosting and maintenance',
            'We have a team of experienced and skilled web designers and developers who can handle any project size and complexity',
            'We work with you to understand your goals, target audience, and brand identity'
        ],
        approach: 'Our approach to web design is based on four principles: simplicity, functionality, elegance and aesthetics. We believe that a website should be easy to navigate, fast to load and pleasing to the eye. We use the latest technologies and best practices to create responsive, user-friendly and secure websites that meet the needs and expectations of our clients. Whether you need a landing page, a portfolio, a blog or an e-commerce site, we can help you achieve your online goals.',
        process: [
            'We listen to your needs and goals and create a customized proposal for your project.',
            'We design a mockup of your website based on your preferences and feedback.',
            'We develop your website using the latest technologies and best practices.',
            'We test your website for functionality, compatibility, and performance.',
            'We launch your website and provide ongoing support and maintenance.'
        ],
        icon:'flaticon-vector',
        projects:'213',
        ssImg1:sgImgS2,
        ssImg2:sgImgS3,
    },
    {
        Id: '2',
        sImgS:brImgS1,
        sTitle: 'E-Commerce',
        description:'Whatever you sell, we make your store goes online. We build your eCommerce with Shopify or WooCommerce. Built to hit the ground running and make money for you.',
        des2:'Whatever you sell, we make your store goes online. We build your eCommerce with Shopify or WooCommerce.',
        des3:'If you are looking for a reliable and affordable way to create and manage your own online store, you should consider e-commerce website services. E-commerce website services are companies that provide you with everything you need to start selling online, such as web design, hosting, payment processing, inventory management, marketing, and customer support. With e-commerce website services, you can focus on your products and customers, while they handle the technical aspects of running your website. Whether you are a small business owner, an entrepreneur, or a hobbyist, e-commerce website services can help you reach a global market and grow your sales.',
        capabilities: [
            'Design and develop custom e-commerce websites that suit your business needs and goals using WooCommerce or Shopify platform',
            'Optimize your online store for speed, security, and user experience',
            'Integrate your e-commerce platform with third-party tools and services such as payment gateways, shipping providers, analytics, etc.',
            'Provide ongoing maintenance and support for your e-commerce website'
        ],
        approach: 'Our approach to e-commerce website services is based on three principles: quality, efficiency and customer satisfaction. We design and develop websites that are user-friendly, secure and optimized for online sales. We also provide maintenance, support. Whether you need a simple online store or a complex e-commerce platform, we have the skills and experience to deliver the best solution for your needs.',
        process: [
            'We analyze your business needs and goals',
            'We design and develop a customized e-commerce website that suits your brand and budget using WooCommerce or Shopify platform',
            'We provide ongoing support and maintenance to ensure your online store runs smoothly and securely'
        ],
        icon:'flaticon-smartphone',
        projects:'102',
        ssImg1:brImgS2,
        ssImg2:brImgS3,
    },
    {
        Id: '3',
        sImgS:uxImgS1,
        sTitle: 'WordPress Support',
        description:'Having trouble with your WordPress website? Please touch us to fix it. We are experienced in helping web agency from Jakarta and Germany. We have solved more than 500+ problems.',
        des2:'Having trouble with your WordPress website? Please touch us to fix it. We are experienced in helping web agency from Jakarta and Germany.',
        des3:'This service is to help you create, manage, and optimize your WordPress site. Whether you need help with installation, customization, security, backups, performance, or troubleshooting, We are have a team of experts ready to help you. You can choose from a variety of plans and packages to suit your needs and budget.',
        capabilities: [
            'Fix bug',
            'Maintenance and backup',
            'Website Optimization',
            'Add new feature'
        ],
        approach: 'Our approach based on three principles: quality, efficiency and reliability. We offer a range of solutions to help you fixing bugs, maintain, add feature and optimize your WordPress site. Whether you need a simple fix or a complex customization, we have the expertise and experience to handle it. Our team of WordPress experts is ready to assist you with any challenge or question you may have. Contact us today and let us take care of your WordPress needs.',
        process: [
            'You contact us via contact form or email',
            'We analyze your request and provide an estimated time',
            'Once you agree, we will immediately work on your request.'
        ],
        icon:'flaticon-palette',
        projects:'60',
        ssImg1:uxImgS2,
        ssImg2:uxImgS3,
    },
    {
        Id: '4',
        sImgS:dvImgS1,
        sTitle: 'Market Analysis',
        description:'Explain to you how all this mistaken idea of denouncing pleasure born and give you complete account the system.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-bar-chart',
        projects:'230',
        ssImg1:dvImgS2,
        ssImg2:dvImgS3,
    }
]    

export default Services;