import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'

const Footer = (props) => {
    const currentYear = new Date().getFullYear();
    return (
        <div className="tp-site-footer text-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-image">
                            <Link className="logo" to="/"><img src={Logo} alt="" /></Link>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="link-widget"></div>
                    </div>
                    <div className="col-12">
                        <div className="copyright">
                            <p>&copy;{currentYear}. All rights reserved by Abdul Cholik Studio.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;