import pimg1 from '../images/project/img-1.png'
import pimg2 from '../images/project/img-2.png'
import pimg3 from '../images/project/img-3.png'


import ps1img1 from '../images/project-single/1.png'
import ps1img2 from '../images/project-single/2.png'
import ps1img3 from '../images/project-single/3.png'



const Projects = [
    
    
    {
      Id:'3',
      pImg:pimg3,
      ps1img:ps1img3,
      title:'Infotech Digital System', 
      subTitle:'Web Design',
      about:'PT. Infotech Digital System is sole distributor for the brand name of LS Cable & System. We provide professional services of one-stop solution for LAN, Fiber Optic & FTTX (Fiber to the X). We have specialized division of of Building, Landed (Residential & Industrial), Structure and MEP division who work indenpendently yet support each other in one coordinated team work.',  
      client: 'infotechdigital.co.id',
      type: 'Web Design',
      duration: '2 Weeks',
      quotes: 'You shouldn\'t be a prisoner of your own ideas.',
      quoteinfo: '- Sol LeWitt -',
      strategies: [
        'Responsive Design: Ensure the website is fully responsive, providing a seamless experience across devices (mobile, tablet, desktop).',
        'Clear Navigation: Simplify the navigation menu, making it easy for visitors to find information about products, services, and company details.',
        'Visual Appeal: Use high-quality images and graphics related to LAN, Fiber Optic, and FTTX. Incorporate LS Cable & System branding to emphasize the partnership.',
        'Fast Loading Times: Optimize the website’s performance to ensure quick loading times, improving user experience and SEO rankings.'
      ],
      approach: 'We specialize in creating websites with a minimalist design. Our approach is to understand the unique value proposition of each product and communicate it effectively to the target audience. We use clean, simple and elegant layouts that highlight the product features and benefits, while avoiding unnecessary distractions and clutter. We also optimize the website performance, usability and accessibility to ensure a smooth and satisfying user experience.'        
   },
   {
      Id:'2',
      pImg:pimg2,
      ps1img:ps1img2,
      title:'Mote Mote', 
      subTitle:'E-Commerce',
      about:'Our universe is made from magical tiny pieces stitched with love and imagination. Reinventing classic bead works in more playful and modern forms to create art pieces that are made to last beyond the season. Every bead works applied to our clothes tell a story of craftsmanships, because each of them are still genuinely handmade.',  
      client: 'mote--mote.com',
      type: 'E-Commerce',
      duration: '1 Month',
      quotes: 'You shouldn\'t be a prisoner of your own ideas.',
      quoteinfo: '- Sol LeWitt -',
      strategies: [
        'Identify the target audience and their preferences',
        'Research the competitors and the best practices for minimalist web design',
        'Create a clear and simple navigation system that highlights the products and their benefits',
        'Optimize the website for speed, performance, and SEO',
        'Test the website on different devices and browsers and collect feedback from users',
        'Monitor the website analytics and make improvements based on data'
      ],
      approach: 'We specialize in creating websites with a minimalist design. Our approach is to understand the unique value proposition of each product and communicate it effectively to the target audience. We use clean, simple and elegant layouts that highlight the product features and benefits, while avoiding unnecessary distractions and clutter. We also optimize the website performance, usability and accessibility to ensure a smooth and satisfying user experience.'        
   },
   {
      Id:'1',
      pImg:pimg1,
      ps1img:ps1img1,
      title:'Placyf',  
      subTitle:'E-Commerce',
      about:'Placyf is an independent lifestyle brand based in Jakarta, Indonesia. The project was initiated by R. Alamsyah in 2017 as an alter ego to channel his ideas with complete freedom and anonymity. Today, Placyf has evolved into a brand that aim to maintain it’s enigmatic persona and provide unique daily wear and lifestyle products suitable for individuals living in today’s intricate environment. Their work is mostly inspired by personal experience, nonconformist mindset, and various counter culture references of literature, art, design, music, film, and style.',
      client: 'placyf.com',
      type: 'E-Commerce',
      duration: '1 Month',
      quotes: 'You shouldn\'t be a prisoner of your own ideas.',
      quoteinfo: '- Sol LeWitt -',
      strategies: [
        'Identify the target audience and their preferences',
        'Research the competitors and the best practices for minimalist web design',
        'Create a clear and simple navigation system that highlights the products and their benefits',
        'Optimize the website for speed, performance, and SEO',
        'Test the website on different devices and browsers and collect feedback from users',
        'Monitor the website analytics and make improvements based on data'
     ],
      approach: 'A minimalist design for an ecommerce website can help to showcase the products and attract the target users. The first step is to identify the product niche and the ideal customer profile. Then, the website layout should be simple, clean and easy to navigate. The color scheme, typography and images should match the brand identity and the product niche. The website should also have clear and compelling copy that highlights the benefits and features of the products, as well as a smooth checkout process that minimizes distractions and encourages conversions.'   
   },
   
    
]

export default Projects;