import React from 'react'
import aImg from '../../images/about/img-1.png'

const About = (props) => {
    return (

        <section className="tf-about-section section-padding">
            <div className="container">
                <div className="tf-about-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="tf-about-img">
                                <img src={aImg} alt="" />
                                <div className="tf-about-img-text">
                                    <div className="tf-about-icon">
                                        <h3>14+</h3>
                                        <span>Years Exprience</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="tf-about-text">
                                <small>about us</small>
                                <h2>Welcome to Abdul Cholik Studio,</h2>
                                <h5>We have 14+ years of experiences in Website Development for give you better services.</h5>
                                <p>A well-experienced might not mean anything if you don't have a sense of aesthetics. As a seasoned team web design and web developer, We are obsessive about a well-running website and its functionality. Yet beautiful and gives a great user experience. We aim to realize your dream project and turn it into your money printer. Let's discuss your project further! </p>

                                <div className="tf-funfact">
                                    <div className="tf-funfact-item">
                                        <h3><span>100</span>+</h3>
                                        <p>Projects Completed</p>
                                    </div>
                                    <div className="tf-funfact-item">
                                        <h3><span>500</span>+</h3>
                                        <p>Solve the problem</p>
                                    </div>
                                    <div className="tf-funfact-item">
                                        <h3><span>100</span>+</h3>
                                        <p>Happy clients</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="visible-rotate-text">
                <h1>About Us</h1>
            </div>
        </section>
    )
}

export default About;