import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





const Testimonials = [
    {
        name: 'Ayu Ananto',
        title: 'CEO of Mote Mote',
        descriptoion: '“Overall, we are satisfied with the result and the fast response upon technical issues. Yet, we often noticed some difficulties and few errors through updating process. Hopefully there will be more technical attention to detail in order to make the clients easier to update or fix.”',
    },
    {
        name: 'Danya Amanda',
        title: 'Founder of Mote Mote',
        descriptoion: '“Overall experience has been good. Responsiveness in resolving issues within the expected period of time. However there is room of improvement in terms of in proactive issue resolution. We look forward to seeing enhancements that can help clients to elevate IT experience to a more satisfactory level.”',
    },
    {
        name: 'R. Alamsyah',
        title: 'Founder of Placyf',
        descriptoion: '“Abdul Cholik Studio is an experienced web developer, they are a team that has a sense of aesthetics. The websites they work on are well executed and functional. They build systems that make it easy for us as clients and our customers.”',
    },
    {
        name: 'Delip Nugraha',
        title: 'Founder of Delipensil',
        descriptoion: '“I am really happy to be able to work with Abdul Cholik Studio, I feel like I work with the real deal, according to expectations, on time, that has become his characteristic, I recommend for those who need WordPress Woocomerce services and other developers, you can contact him, Thank you very much Abdul Cholik Studio.”',
    },
]



const Testimonial = () => {

    var settings = {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: false
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false
                }
            }
        ]
    };

    return (

        <section className="tp-testimonial-section section-padding">
            <div className="container">
                <div className="tp-section-title">
                    <span>Testimonials</span>
                    <h2>What Our clients say.</h2>
                </div>

                <div className="tp-testimonial-wrap">
                    <Slider {...settings}>
                        {Testimonials.map((tstml, tsm) => (
                            <div className="tp-testimonial-item" key={tsm}>
                                <div className="tp-testimonial-text">
                                    <p>{tstml.descriptoion}</p>
                                    <span>{tstml.name} <br></br>({tstml.title})</span>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <div className="visible-rotate-text">
                <h1>Review</h1>
            </div>
        </section>
    )
}

export default Testimonial;